<template>
  <div class="index">
    <div class="resources-content">
      <div class="select-wrapper">
          <div class="select-left">
            <span class="text">行业分类</span>
             <el-cascader
            v-model="examinee_id"
            :options="options"
            :props="cateListProps"
            style="width: 250px"
            clearable
            @change="examineeCha"
          ></el-cascader>
           <span class="text" style="margin-left: 10px;">商品名称</span>
            <el-input ref="name" v-model="inquire" clearable  style="width: 250px;margin-left: 10px;" @change="inquireFun"></el-input>
             <el-button type="primary" style="margin-left: 10px;" @click="inquireFun">查询</el-button>
          </div>
          
          <div class="select-left">
              <el-button type="primary" @click="addClassify">创建商品库</el-button>
          </div>
      </div>
       <el-scrollbar style="height: 100%;">
         <el-table :data="subjectListTable" class="customTable" style="width: 100%; flex: 1" >
            <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
            <el-table-column prop="class_name" label="行业分类" align="center"></el-table-column>
            <el-table-column prop="answer_time" label="商品使用时间" align="center">
               <template slot-scope="scope">
                      <span>{{Math.floor(scope.row.answer_time/60)}}分{{Math.floor(scope.row.answer_time%60)}}秒</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                   <el-link type="primary" :underline="false" @click="copy(scope.row)">复制</el-link>
                    <el-link type="primary" :underline="false" @click="editSubject(scope.row)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="deleteSubject(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        </el-scrollbar>
        <el-pagination
        class="pages-center"
        :current-page="coursePages.currentPageNum"
        :page-size="coursePages.eachPageNum"
        layout="prev, pager, next, jumper"
        :total="coursePages.total"
        @current-change="courseCurrentChange"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { commodityList,commodityDate,commodityDel,copyGoods } from "@/utils/apis";
export default {
    data(){
        return{
            subjectListTable:[
                {
                    id: 1,
                    time: "2016-05-02",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                }   ,
                 {
                    id: 1,
                    time: "2016-05-02",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                }       
            ],
            inquire:'',
            cateListProps: {
              value: "category_id",
              label: "category_name",
              children: "children",
            },
            options: [],
            examineeList:[],
            examinee_id:'',
             //分页
            coursePages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0,
            },
        }
    },
    mounted(){
      this.getGoodsCategoryList()
      this.getList()
    },
    methods:{
        //获取行业分类数据
        getGoodsCategoryList(){
            let params = {
              paging: 0,
            };
            commodityList(params).then((res) => {
              if (res.code === 200) {
                this.options = res.data.list;
              }
            });
        },
        //查询
        inquireFun(){
          this.coursePages.currentPageNum = 1
          this.getList()
        },
        //获取列表数据
        getList(){
           let params = {
              paging: 1,
              page:this.coursePages.currentPageNum,
              pageSize:this.coursePages.eachPageNum,
              goods_class_id:this.examinee_id[1],
              goods_name:this.inquire
            };
            commodityDate(params).then((res) => {
              if (res.code === 200) {
                this.subjectListTable = res.data.list
                this.coursePages.total = res.data.total
              }
            });
        },
           //复制
          copy(row){
            console.log(row)
            let params = {
              id: row.id,
            };
            copyGoods(params).then((res) => {
              if (res.code === 200) {
                this.getList()
              }
            });
            
          },
        //编辑
        editSubject(row){
            this.$router.push({
              path: "/liveStreaming/commodity-establish",
              query: { id: row.id },
            });
        },

        //删除
        deleteSubject(row){
           this.$confirm(`是否删除该分类，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let param = {
                      id:row.id	
                    }
                    commodityDel(param).then((res) => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            duration:1000,
                            onClose: () => {
                                this.getList()
                            }
                        })
                    }).catch((err) => {
                        console.log('err', err)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
        },


        //行业分类改变事件
        examineeCha(){
            this.getList()
        },
        //添加商品
        addClassify(){
           this.$router.push({
                    path: '/liveStreaming/commodity-establish'
                })
        },
        // 切换分页
        courseCurrentChange(val) {
        this.coursePages.currentPageNum = val;
         this.getList()
        },
        
    }
}
</script>



<style  lang="scss" scoped>

 .pages-center {
    margin: 20px 0 20px;
    text-align: center;
   }
.index {
  box-sizing: border-box;
  height: 100%;
  padding: 20px 20px 0;
  .el-scrollbar {
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
  }
}
.resources-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .management-admin-header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
  }
}

.select-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  .select-left {
       display: flex;
        align-items: center;
        margin-bottom: 20px;
    .text {
      font-size: 14px;
      color: #333333;
      margin-right: 10px;
    }
    .resources-cascader {
      width: 420px;
      ::v-deep .el-input__inner {
        border-radius: 0;
      }
    }
  }
  .select-btn {
    i {
      font-size: 12px;
      margin-right: 5px;
    }
  }
 
}
</style>